import { useTranslation } from "react-i18next"


export default function useConvertJSON () {

  const { t } = useTranslation("translation")
 
  function json2array(obj, isMarsDictionares=true) {

    let json =  isMarsDictionares ? t(obj, { ns: 'mars_dictionaries' }) : t(obj);
    let result = []
    let keys = Object.keys(json)
    
    keys.forEach(function (key) {
      result.push({ [key]: json[key] })
    })

    return result
  }

  return { json2array }
}
