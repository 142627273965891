import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import Tooltip from '@mui/material/Tooltip'
import { CustomToolbar, DisplayCustomStatus } from '../../components'
import { ChatBubbleOutlineSharp, Create } from '@mui/icons-material'
import { AccordionTable, AccordionFetch, AccordionFetchGroup } from './index'
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

import SaveIcon from '@mui/icons-material/Save';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

function FlexibleTabs (props) {

  const { data, dataConvert, dataColumns, accordion, breadCrumb, title, hiddenBreadCrumb=false, trigger = () => {}, setTrigger = () => {}, userRoles = null, extraButton=[]}  = props
  

  function displayObjects (convert) {
    if (convert) {
      return convert.map(item => {
        const { title, children, isButton, onHandleClick, isAccess } = item

        return (
          <div className='bpx-section-detail' key={title}>
            <div className='bpx-top-detail bpx-editable-detail'>{title}
            { isButton && isAccess &&
                <SaveIcon  sx={{fontSize: '1.2rem', cursor: 'pointer'}} onClick={onHandleClick}/>  
            }
            </div>
            <div className='bpx-bottom-detail'>
              { displayArrayChildren(children, isAccess) }
            </div>
          </div>
        )

      })
    } else {
      return "There is no data"
    }
  }


  function displayArrayChildren (children, isAccess=false) {
    if (children) {
      return children.map(sub => {
      
          
        if (sub.key === "AE_MATERIAL_STATUS" || sub.key === "BP_STATUS") {
          return <DisplayCustomStatus data={sub} key="uniqe_key" />
        } else {
          return displayObjChildren(sub, isAccess)
        }
            
      })
  
    } else {
      return "There is no children items"
    }
  }


  function displayObjChildren(object, isAccess) {

    const { editable, spacer, key, label, tooltip, value, getStyle, type, valueOptions, onHandleInput, onHandleClear, renderCell } = object
    const style = getStyle ? getStyle() : {}; 
    if(type&&type=='singleSelect'&&isAccess){
      return(
        <div className={`bpx-field-detail ${(spacer) ? 'bpx-field-margin' : ''}`} key={key}>
          <div className='bpx-key-detail' style={{paddingTop: '0.125rem'}}>
            <p>{label}</p>
          </div>
          <div className='bpx-value-detail bpx-value-detail-dropdown'>
            <DeleteOutlineIcon
              className='bpx-icon-trashcan'
              onClick={userRoles === "AE Support Display" || userRoles === null ? null : onHandleClear}
            />
            <FormControl size="small" key={key} sx={{width: '100%'}}>
            {/* <InputLabel id="demo-simple-select-label">{label}</InputLabel> */}
              <Select
                sx={{
                  maxWidth: '100%', 
                  width: '150px',
                  height: '1.25rem', 
                  fontSize: '0.75rem'
                }}
                labelId="demo-simple-select-label"
                value={value|| ''}
                onChange={onHandleInput}//{(e) => onHandleInput(field,valueParser?valueParser(e.target.value):e.target.value)}
                className='bpx-modal-add-data-field'
              >
                {valueOptions ?
                  valueOptions.map((item, i) => {
                    return <MenuItem key={i} value={item}>{item}</MenuItem>
                  })
                  : null}
              </Select>
            </FormControl>   
          </div>
      </div>
      )
    }


    return (
      <div className={`bpx-field-detail ${(spacer) ? 'bpx-field-margin' : ''}`} key={key}>
        <div className='bpx-key-detail'>
          <p>{label}</p>
        </div>
        <div className='bpx-value-detail'>
          {
            (editable) ? (
              <div className='bpx-editable-detail'>
                <div className='bpx-input-section'>
                  <OutlinedInput placeholder="Please enter text" />
                </div>
                <div className='bpx-icon-section'>
                  <Create className='bpx-edit-icon' />
                  <Tooltip title={tooltip} placement='right'>
                    <ChatBubbleOutlineSharp className='bpx-tooltip-icon' />
                  </Tooltip>
                </div>
              </div>
            ) : 
            renderCell?
            renderCell(value)
            :(
              <p style={style}>{value && value !== "Invalid date" ? value : "n/a"}</p>
            )
          }
        </div>
      </div>
    )
  }


  function displayMapping (accordion) {
    return accordion.map((tab) => {

      const { 
        id, dataKey, label, collapse, fetching, 
        url, idField , fetchKey, CRUD, endpointCRUD, keysCRUD, notFetchingExpand, isPages, additional_key
      } = tab

      return displayTableAccordion(
        data, dataColumns, id, dataKey, label, collapse, 
        fetching, url, idField, fetchKey, CRUD, endpointCRUD, keysCRUD, notFetchingExpand, isPages, additional_key
      )

    })
  }


  function displayTableAccordion (
    obj, columns, id, dataKey, label, collapse, 
    fetching, url, idField, fetchKey, CRUD, endpointCRUD, keysCRUD, notFetchingExpand, isPages, additional_key) {

    if (!obj) {
      return "Data from server couldn't load"
    }
    
    
    if (fetching === false) {
      return (
        <AccordionTable
          key={id} 
          id={id}
          data={obj}
          columns={columns[dataKey]}
          dataKey={dataKey}
          label={label}
          collapse={collapse}
          fetching={fetching}
          url={url}
          idField={idField}
        />
      )
    } else if (CRUD === true) {

      return (
        <AccordionFetchGroup
          key={id} 
          id={id}
          data={obj}
          fetchKey={fetchKey}
          columns={columns[dataKey]}
          dataKey={dataKey}
          label={label}
          collapse={collapse}
          fetching={fetching}
          url={url}
          idField={idField}
          CRUD={CRUD}
          endpointCRUD={endpointCRUD}
          keysCRUD={keysCRUD}
          notFetchingExpand={notFetchingExpand}
        />
      )

    } else {
      
      return (
        <AccordionFetch
          key={id} 
          id={id}
          data={obj}
          fetchKey={fetchKey}
          columns={columns[dataKey]}
          dataKey={dataKey}
          label={label}
          collapse={collapse}
          fetching={fetching}
          url={url}
          idField={idField}
          isPages={isPages}
          trigger={trigger}
          setTrigger={setTrigger}
          additional_key={additional_key}
        />
      )

    }
      
    
  }

  return (
    <div className='tableContainer'>

      <CustomToolbar 
        bread={breadCrumb} 
        title={title} 
        hiddenBreadCrumb={hiddenBreadCrumb}
        extraButton={extraButton}
      />
      <div className='bpx-bg-detail'>
        <div className='bpx-block-detail'>

          <div className='bpx-left-side'>
            { displayObjects(dataConvert) }
          </div>

          <div className='bpx-right-side'>
            { displayMapping(accordion) }
          </div>

        </div> 

      </div>
    </div>
  )


}

export default FlexibleTabs
