import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from './ProvideAuth'

import { 
  CssBaseline, TextField, Link, Paper, Box, Grid, 
  Typography, InputLabel, MenuItem, Select, 
  CardMedia, Stepper, Step, StepLabel, StepContent, Button, 
  Input, IconButton, InputAdornment, FormControl
} from '@mui/material'

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import MenuIcon from "@mui/icons-material/Menu"
import Image from '../../assets/images/Sing-in-Page.png'
import MarsLogo from '../../assets/images/mars_logo.png'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { backend_url } from '../../settings'
import { useTranslation } from 'react-i18next'
import { useFetch } from '../../hooks'
import SnackbarUtils from '../../utils/SnackbarUtils'
import { useUserPrefs } from '../UserPreferences/ProvideUserPrefs'

import { useOktaAuth } from '@okta/okta-react';
import HelpLoginComponent from './HelpLoginComponent';

function Copyright(props) {
  
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {' © BPX ' + new Date().getFullYear() + ' | '}
      <Link color="inherit" href="https://www.bpxglobal.com">
        www.bpxglobal.com
      </Link>
    </Typography>
  );
}

export default function SupportLogin() {

  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [isOkta, setIsOkta] = useState(false)
  const { t } = useTranslation('translation')
  const request = useFetch()
  const { saveUserPrefs } = useUserPrefs()
  const [ passwordExpiration, setPasswordExpiration ] = useState(null)
  const [tokenState, setTokenState] = useState('')
  const [refreshToken, setRefreshToken] = useState(null)
  const [activeStep, setActiveStep] = useState(0)
  const [status, setStatus] = useState(false)
  const [businessUnits, setBusinessUnits] = useState([])
  const [businessUnit, setBusinessUnit] = useState('')
  const [currentUser, setCurrentUser] = useState({})
  const [showPassword, setShowPassword] = useState(false);
  const [logo, setLogo] = useState({src: '', slogan: ''})
  const [state, setState] = useState({
    username: '',
    password: '',
    grant_type: 'password',
  })

  let navigate = useNavigate()
  let location = useLocation()
  let auth = useAuth()
  let from = location.state ? location.state.from.pathname : "/";

  useEffect(()=>{
    if(from.length>1){
      localStorage.setItem('fromPage', from)
    }
  },[from])

  useEffect(()=>{
    request.fetchData(
      backend_url.enterprise_identification, 
      'GET', 
      null,
      false,
      '',
      false,
      false,
      true,
      false, 
      false,
      true
    ).then(data=>{
      const urlCreator = window.URL || window.webkitURL;
      const src = data.body.type.includes('image') ? urlCreator.createObjectURL(data.body) : '';
      setLogo({slogan: data.title, src})
    })
  },[])

  useEffect(() => {

      const fetchAccessToken = async () => {
          const accessToken = await oktaAuth.getAccessToken()
          const refreshToken = await oktaAuth.getRefreshToken()
          localStorage.setItem('refreshOkta', true)
          setIsOkta(true)
          getCurrentUser({access_token: accessToken});
      }

      if (!authState || !authState.isAuthenticated) {
          setUserInfo(null);
      } else {
          oktaAuth.getUser().then((info) => {
              setUserInfo(info);
              fetchAccessToken() 
          }).catch((err) => {
          }); 
      }
  }, [oktaAuth, authState])

  const okta_login = async () => {
    oktaAuth.signInWithRedirect({ originalUri: '/' });
  };

  const login = () => {
    auth.signin()
    const from = localStorage.getItem("fromPage")||'/'
    navigate(from, { replace: true })
    localStorage.setItem('fromPage', '/')
    if (!isOkta) {
      if (!passwordExpiration || passwordExpiration === 'None' || new Date(passwordExpiration) < new Date()){
          SnackbarUtils.error("Your password has expired. For security reasons we recommend to change it as soon as possible")
      } else {
          const days = Math.ceil( Math.abs(new Date() - new Date(passwordExpiration)) / (1000 * 60 * 60 * 24));  
          if (days<6) {
            SnackbarUtils.error(`Your password will expire in ${days} days. For security reasons we recommend to change it as soon as possible`)
          }
      }
    }
  }

  const handleClickShowPassword = () => setShowPassword((prev) =>!prev);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleNext = async () => {
    setStatus(!status)

    if (!status) {
      request.fetchData(
        backend_url.login, 
        'POST', 
        state, 
        true,
        '',
        false
      )
      .then(response => {
        if (response) {
          setRefreshToken(response.refresh_token)     
          getCurrentUser(response)
        }
        
        if (!response) {
          setStatus(false)
        }
      })
      .catch((err)=>{
        if (err === 400) {
          SnackbarUtils.error("Incorrect username or password")
        } else {
          SnackbarUtils.error("Server error")
          console.log(err)
        }
      })     
    }

  }
  
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setStatus(false)
  }

  const handleChange = (event) => {
    const { value, name } = event.target
    setState({ ...state, [name]: value })
  }

  const getCurrentUser = (token) => {

    let access_token = (token.access_token === undefined) ? token.accessToken : token.access_token

    request.fetchData(
      `${backend_url.get_current_user}?is_login_flow=true`,
      'GET',
      null,
      false,
      `"${access_token}"`,
      false
    )
    .then((data) => {
        
      const checkLogin = data.reports.Logging_page.join("")

      if (isOkta)
        setRefreshToken(null)
      
      if (checkLogin === "Logging_in") {
        SnackbarUtils.success(JSON.stringify('Successfully fetched'));
        getBU(access_token)
        setCurrentUser(data)
        getUserPrefs(access_token, data)
        getUserRoles(access_token, data)
        setPasswordExpiration(data.password_expiration)
      } else {

        setActiveStep(0)
        setStatus(false)
        SnackbarUtils.error("You do not have access to the BPX platform")
      }

    })
    .catch((err) => {
      if(access_token){
        const okta = localStorage.getItem('okta-token-storage')
        if(okta&&Object.keys(JSON.parse(okta)).length){
          const from = localStorage.getItem("fromPage")||'/'
          auth.signin(true)
          if(from.includes('provide_response')){
            navigate(from, { replace: true })
          }else{
            SnackbarUtils.error("Your SSO user has no access to the BPX platform");
            navigate('/no-access', { replace: true })
          }
        }else{
          SnackbarUtils.error("User has inactive status");
        }

      }
      

    })    

  }

  const getBU = (access_token) => {

    setTokenState(access_token)

    request.fetchData(
      backend_url.config_business_units, 
      'GET',
      null,
      false,
      `"${access_token}"`,
      false      
    )
    .then((data) => {
      setBusinessUnits(data)
      setActiveStep(activeStep + 1)
    })

  }
  
  const handleSubmit = (event) => {

    if (businessUnit) {
        localStorage.setItem('token_bpx', JSON.stringify(tokenState))
        localStorage.setItem('refresh_token', JSON.stringify(refreshToken))
        localStorage.setItem('bu_bpx', businessUnit)
        localStorage.setItem('current_user', JSON.stringify(currentUser))
        localStorage.setItem('b_units', JSON.stringify(businessUnits))

        login()
      }

  }

  const getUserPrefs = async (access_token, userData) => {
      request.fetchData(
          `${backend_url.get_user_prefs}/${userData.username}`,
          'GET',
          null,
          false,
          `"${access_token}"`,
          false
      )
      .then((data) => {
          saveUserPrefs(data)
      })
  }

  const getUserRoles = async (access_token, userData) => {
    request.fetchData(
        `${backend_url.user_roles}/${userData.username}`,
        'GET',
        null,
        false,
        `"${access_token}"`,
        false
    )
    .then((data) => {
        localStorage.setItem('userRoles', data)
    })
}



  const steps = [
    {
      label: t("Login.authorization"),
      description: 
       <React.Fragment>
         
            <InputLabel sx={{ fontFamily: "Montserrat", fontSize: 12, mt: '20px' }} htmlFor="username">{t("Login.username")}</InputLabel>
                        <TextField
                          required
                          fullWidth
                          id="username"
                          name="username"
                          label=""
                          autoComplete="username"
                          autoFocus
                          variant="standard"
                          value={state.username}
                          onChange={handleChange}
                          InputProps={{ className: 'bpx-login-input', style: { width: 250, fontWeight: 'bold', fontSize: 20, fontFamily: "Montserrat" } }}

                        />

            <InputLabel sx={{ fontFamily: "Montserrat", fontSize: 12, mt: '20px' }} htmlFor="password">{t("Login.password")}</InputLabel>
                        <Input 
                            id="password"
                            sx={{
                              width: 250,
                            }}
                            className='bpx-login-input'
                            type={showPassword? 'text' : 'password'}
                            name='password'
                            variant="standard"
                            fullWidth
                            required                            
                            value={state.password}
                            autoComplete="current-password"
                            endAdornment={
                              <InputAdornment position="end" sx={{marginRight: '10px'}}>
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                            onChange={handleChange}
                            label=''
                        />

       </React.Fragment>
      ,
    },
    {
      label: t("Login.businessUnit"),
      description: 
        <React.Fragment>
           <FormControl size="small">
          <Select
            IconComponent={KeyboardArrowDownOutlinedIcon}
            required
            sx={{ width: 250, fontFamily: "Montserrat"}}
            id="business-unit"
            defaultValue=""
            variant="standard"
            className="bpx-login-page-select-bu"
            onChange={(e) => setBusinessUnit(e.target.value)}
          >
            {businessUnits.map(item => <MenuItem key={item.BUSINESS_UNIT} value={item.BUSINESS_UNIT} >{item.DESCRIPTION}</MenuItem>)}
          </Select>
          </FormControl>
        </React.Fragment>,
    },
  ]

  return (
    <Grid
      container
      component="main"
      position="fixed"
      height="60px"
      width="100%"
      sx={{ height: '100vh' }}
      columns={16}
    >
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={6}
        md={9}
        sx={{
          backgroundImage: `url(${Image})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundColor: '#2b2b39',
        }}
      >
        <Copyright sx={{ mt: '95vh', color: 'white', fontSize: 12 }} />
      </Grid>
      <Grid item xs={12} sm={10} md={7} component={Paper} elevation={6} square>
        <Grid sx={{ justifyContent: 'flex-end', alignItems: "center", pr: 5, display: "flex", mt: 1 }} >
          <HelpLoginComponent/>
        </Grid>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CardMedia
            sx={{
              width: "auto",
            }}
            component="img"
            src={logo.src} />
          <Typography fontSize='18' fontWeight='bold' fontFamily="Montserrat" align="center">
            {logo.slogan}
          </Typography>
          <Typography mt='50px' sx={{ fontFamily: "Montserrat" }}>
            {t("Login.sign")}
          </Typography>
          <Typography sx={{ fontFamily: "Montserrat" }}>
            {t("Login.warning")}
          </Typography>

          <Box component="form" noValidate sx={{ maxWidth: 400, marginTop: '20px' }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      index === 2 ? (
                        <Typography variant="caption">{t("Login.lastStep")}</Typography>
                      ) : null
                    }
                  >
                    {step.label}
                  </StepLabel>

                  <StepContent >

                    <div>{step.description}</div>

                    <Box sx={{ mb: 2, mt: 2 }}>

                      <div>

                        {
                          index === steps.length - 1 ? (
                            businessUnit && (
                              <Button variant="contained" onClick={handleSubmit} sx={{ mt: 1, mr: 1 }}>
                                {t("Login.signin")}
                              </Button>
                          )

                          ) : (
                            <>
                              <Button variant="contained" disabled={status} onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                                {t("Login.continue")}
                              </Button>
                          </>
                        )
                        }
                        
                      { index !== 0 && <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                          {t("Login.back")}
                        </Button>
                      }

                      </div>

                    </Box>

                  </StepContent>

                </Step>
              ))}
            </Stepper>
          </Box>

        </Box>
      </Grid>
    </Grid>
  );
}
