import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridPro, GridActionsCellItem, GridOverlay } from '@mui/x-data-grid-pro'
import { LinearProgress } from "@mui/material"
import { backend_url } from '../../settings'
import { useFetch } from '../../hooks'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import { ModalAddData, ModalDelete, CustomToolbar } from '../../components'
import { getParam } from "../../utils/utils"
import createOptions from './createOptions'
import { dateTimeFormatter, makeFormat, checkSize } from "../../utils/utils"
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs";
import moment from 'moment'
import cronstrue from 'cronstrue';

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  )
}


export default function Scheduler() {

  const { t } = useTranslation('translation')
  const request = useFetch()
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const { prefsData, saveUserPrefs } = useUserPrefs()

  const [open, setOpen] = useState({ addData: false, deleteData: false });
  const [options, setOptions] = useState([])
  const [rows, setRows] = useState([])
  const [editData, setEditData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingForm, setLoadingForm] = useState(true);
  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.Scheduler || 'm');

  const columns = [
    {
      field: 'job_name',
      headerName: t('Scheduler.job_name'),
      minWidth: 150,
      width: 200
    },
    {
      field: 'job_type',
      headerName: t('Scheduler.job_type'),
      minWidth: 150,
      width: 150
    },
    {
      field: 'crontab_expr',
      headerName: t('Scheduler.crontab_expr') + '/' + t('Scheduler.run_date'),
      minWidth: 200,
      width: 400,
      flex: 1,
    },
    {
      field: 'timezone',
      headerName: t('Scheduler.timezone'),
      minWidth: 100,
      width: 100
    },
  ]

  const endpoinPost = [
    { line: 'job_name' },
    { line: 'crontab_expr' }
  ]

  const endpointDelete = [{
    field: 'job_id'
  }]

  const endpointOneTime = [
    { line: 'job_name' },
    { line: 'run_date' }
  ]


  const columnsSingleSelect = [
    { field: 'business_unit', url: backend_url.config_business_units, code: "BUSINESS_UNIT" },
  ]

  const actions = [{
    field: 'actions',
    type: 'actions',
    headerName:  t('Scheduler.actions'),
    width: 130,
    cellClassName: 'actions',
    getActions: ({ id, row }) => {
      const editDeleteArr = [
        // <GridActionsCellItem
        //   icon={<EditIcon />}
        //   label="Edit"
        //   className="textPrimary"
        //   onClick={handleEditClick(id)}
        //   color="inherit"
        // />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />
      ]

      return editDeleteArr
    },
  },]


  const renderCellForDate = ({ value }) => {
    if (value) {
      return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
    }
    return value
  };

  const valueParserForDate = (value) => {
    if (value) {
      return moment(value).format("YYYY-MM-DD")
    }
  }

  const fetchData = () => {
    setLoading(true)
    request.fetchData(
      backend_url.jobs,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    ).then(data => {
      setLoading(false)
      setRows(data.map((it) => {
        if (it.job_type === 'date') {
          return {
            ...it,
            id: it.job_id,
            job_type: t('Scheduler.one_time'),
            crontab_expr: dateTimeFormatter(it.run_date, prefsData.date_format, prefsData.time_format, 'UTC') + ' UTC'
          }
        }
        return {
          ...it,
          id: it.job_id,
          job_type: t('Scheduler.permanent'),
          crontab_expr: cronstrue.toString(it.crontab_expr, { use24HourTimeFormat: prefsData.time_format === '24H' })
        }
      }

      ))
    }).catch(() => {
      setLoading(false)
    })
  }

  const fetchOptions = (stateSelect) => {
    request.fetchData(
      backend_url.schedulable_jobs,
      'GET',
      null,
      false,
      tokenBpxRequest,
    ).then(data => {
      setLoadingForm(false)
      setOptions([
        {
          field: 'job_type',
          headerName: t('Scheduler.job_type'),
          flex: 1,
          required: true,
          style: {
            height: '40px',
            flexBasis: '30%'
          },
          valueOptions: [
            {
              value: 'permanent',
              option: t('Scheduler.permanent')
            },
            {
              value: 'one_time',
              option: t('Scheduler.one_time')
            }
          ],
          type: 'singleSelect',
          optionsParser: (value) => {
            if (value === 'permanent') {
              return {
                field: 'crontab_expr',
                valueRow: {
                  field: 'crontab_expr',
                  headerName: t('Scheduler.crontab_expr'),
                  flex: 1,
                  type: 'crontab_expr',
                  minWidth: 200,
                  defaultType: 'empty',
                  required: true,
                  defaultValue: '0 0 * * *'
                },
              }
            } else if (value === 'one_time') {
              return {
                field: 'crontab_expr',
                valueRow: {
                  field: 'crontab_expr',
                  headerName: t('Scheduler.run_date'),
                  flex: 1,
                  type: 'date-and-time',
                  defaultType: 'empty',
                  format: makeFormat(prefsData.time_format),
                  style: {
                    height: '60px', 
                    marginRight: '100%' 
                  },
                  minWidth: 200,
                  required: true,
                  description: 'Add time in UTC Format',
                  renderCell: ({ value }) => {
                    if (value) {
                      return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, 'UTC', true)
                    }
                    return value
                  },
                  valueParser: (value) => {
                    if (value) {
                      return moment(value).format('YYYY-MM-DD HH:mm:ss') // DateTime.fromISO(moment(value).format('YYYY-MM-DDTHH:mm:ss'), {zone: prefsData.time_zone }).setZone( prefsData.time_zone).toUTC().toFormat('yyyy-MM-dd HH:mm:ss')//moment(value).format('YYYY-MM-DD HH:mm:ss')
                    }
                  },
                }
              }
            }
          }
        },
        {
          field: 'job_name',
          headerName: t('Scheduler.job_name'),
          flex: 1,
          type: 'singleSelect',
          style: {
            height: '40px',
            flexBasis: '35%'
          },
          valueOptions: data.map(it => it.job_name),
          required: true,
          minWidth: 180,
          optionsParser: (value) => {
            const options = data.find(it => it.job_name === value).params.map(it => createOptions(it, renderCellForDate, valueParserForDate, stateSelect));
            const defaultValue = {};
            options.filter(it => it.defaultOption || it.defaultOption === false || it.defaultOption === 0).forEach(it => {
              defaultValue[it.name] = it.defaultOption
            })

            return {
              field: 'additionalProp',
              valueRow: {
                field: 'additionalProp',
                headerName: 'additionalProp',
                flex: 1,
                minWidth: 180,
                type: 'array',
                defaultType: 'empty',
                multiple: false,
                defaultValue,
                components: options,
                valueParser: (value, name) => {
                  if (value) {
                    return value[name]
                  } return ''

                },
                valueFormatted: (state, value, name) => {
                  if (value) {                 
                    return { ...state, [name]: value }
                  } else {
                    delete state[name];
                    return state
                  }

                },

              },
            }
          }
        },
        {
          field: 'crontab_expr',
          headerName: 'crontab_expr',
          flex: 1,
          type: 'empty',
          minWidth: 200,
        },

        {
          field: 'additionalProp',
          headerName: 'additionalProp',
          flex: 1,
          type: 'empty',
          minWidth: 200,
        },
      ])
    })
  }


  const fetchColumnSingleSelect = () => {
    return columnsSingleSelect.map(item => {
      const { field, url, code } = item;
      return request.fetchData(
        url,
        'GET',
        null,
        false,
        tokenBpxRequest,
        false,
        false
      )
        .then(data => {
          if (data.length) {
            let valueArray = data.map(tt => tt[code])
            let unique = valueArray.filter((v, i, a) => a.indexOf(v) === i)
            return { [field]: unique }
          }
          return {}

        })
        .catch(() => {
          return {}
        })
    })
  }
  useEffect(() => {
    fetchData();
    Promise.all(fetchColumnSingleSelect()).then(data => fetchOptions(data.reduce((result, currentObject) => ({ ...result, ...currentObject }), {})))
  }, [])


  const handleDeleteClick = (id) => () => {
    setOpen(prev => ({ ...prev, deleteData: true }))
    setEditData(rows.find(it => it.id === id))
  }

  const handleEditClick = (id) => () => {
    setEditData(rows.find(it => it.id === id))
    setOpen(prev => ({ ...prev, addData: true }))
  }

  const handleAddClick = () => {
    setOpen(prev => ({ ...prev, addData: true }))
  }


  const onCloseEdit = () => {
    setOpen(pr => ({ ...pr, addData: false }))
    setEditData(null)
  }


  const onCloseDelete = () => {
    setOpen(pr => ({ ...pr, deleteData: false }));
    setEditData(null)
  }

  const getUrl = (newRow, endpoint) => {
    let param = {};
    let convertUrl = ''
    if (endpoint) {
      endpoint.filter(it => it.line).map(it => it.line).forEach(it => {
        if (newRow[it]) {
          param[it] = newRow[it];
        }
      })
      convertUrl = endpoint.filter(it => it.field).map(it => it.field).map(it => newRow[it]).join('/');
    }

    const queryString = getParam(param).toString();

    if (convertUrl) {
      return '/' + convertUrl + (queryString ? '?' + queryString : '')
    }
    return queryString ? '?' + queryString : '';
  }

  const handleSubmit = (data) => {
    setLoadingForm(true)
    const { isNew, job_type, job_name, crontab_expr, additionalProp } = data
    if (isNew) {
      if (job_type === "permanent") {
        request.fetchData(
          backend_url.job + getUrl(data, endpoinPost),
          'POST',
          JSON.stringify(additionalProp),
          false,
          tokenBpxRequest,
        ).then((data) => {
          fetchData()
          setOpen(pr => ({ ...pr, addData: false }));
          setLoadingForm(false)
        }).catch(() => setLoadingForm(false))
      } else if (job_type === "one_time") {
        request.fetchData(
          backend_url.job_one_time + getUrl({ ...data, run_date: crontab_expr }, endpointOneTime),
          'POST',
          JSON.stringify(additionalProp),
          false,
          tokenBpxRequest,
        ).then((data) => {
          fetchData()
          setOpen(pr => ({ ...pr, addData: false }));
          setLoadingForm(false)
        }).catch(() => setLoadingForm(false))
      }
    }
  }

  const handleDelete = () => {
    const id = editData.job_id
    request.fetchData(
      backend_url.job + getUrl(editData, endpointDelete),
      'DELETE',
      null,
      false,
      tokenBpxRequest,
    ).then(() => {
      setRows(rows.filter(it => it.id != id))
      onCloseDelete()
      setLoading(false)
    })
  }

  const makeColumn = (data) => {
    return {
      field: data,
      headerName: data,
      minWidth: 150,
      flex: 1
    }
  }


  const getDetailPanelContent = (data) => {
    return (<DataGridPro
      sx={{ 
        '& .MuiDataGrid-row': {
          minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
          height: 'auto !important'
        },
        '& .MuiDataGrid-cell': {
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        },
        '& .MuiDataGrid-main': { 
          height: '100%',
          fontSize: checkSize(fontSize, 'font_size')
        }, 
        marginLeft: '20px' 
      }}
      autoHeight={true}
      getRowHeight={() => 'auto'}
      headerHeight={checkSize(fontSize, 'header_height')}
      rows={[{ ...data.row.job_args, id: 0 }]}
      columns={Object.keys(data.row.job_args).map(it => (makeColumn(it)))}
      hideFooter={true}
    />)
  }

  const objBreadcrumb = [
    { label: t('Breadcrumb.home'), link: '/' },
    { label: t('Breadcrumb.support'), link: '' },
    { label: t('Breadcrumb.scheduler'), link: '/support/scheduler' },
  ]

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        Scheduler: value
      }
    })
  }

  return (
    <div className="tableContainer">
      <DataGridPro
        sx={{ 
          '& .MuiDataGrid-row': {
            minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
            height: 'auto !important'
          },
          '& .MuiDataGrid-cell': {
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },
          '& .MuiDataGrid-main': { 
            height: rows.length < 8 ? '100%' : '50vh',
            fontSize: checkSize(fontSize, 'font_size')
          } 
        }}
        autoHeight={Boolean(rows.length < 8)}
        rows={rows}
        columns={[...actions, ...columns]}
        loading={loading}
        hideFooter={true}
        getRowHeight={() => 'auto'}
        headerHeight={checkSize(fontSize, 'header_height')}
        componentsProps={{
          toolbar: {
            bread: objBreadcrumb,
            title: t("Scheduler.Jobs"),
            isFontSize: true,
            fontSize: fontSize,
            onChangeFontSize: onChangeFontSize,
          }
        }}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
          Toolbar: CustomToolbar,
        }}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={({ row }) => 'auto'}
        localeText={{
          noRowsLabel: t("Common.noRows")
        }}
      />
      <div className="bpx-config-datagrid-bottom">
        <Button
          className="bpx-config-datagrid-btn"
          startIcon={<AddIcon />}
          onClick={handleAddClick}
        />
      </div>
      <ModalAddData data={options} open={open.addData} titleTable={'Jobs'} onClose={onCloseEdit} handleSubmit={handleSubmit} loading={loadingForm} defaultClass='jobs' />
      <ModalDelete open={open.deleteData} onSubmit={handleDelete} onClose={onCloseDelete} />
    </div>


  )
}