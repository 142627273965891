import { useTranslation } from 'react-i18next'
import { backend_url } from '../../../../settings'
import  HookCRUDTableUserList  from '../../../../hooks/HookCRUDTable/HookCRUDTableUserList'
import { Link } from 'react-router-dom'
import DatePicker from '../../Components/DatePicker'
import { dateTimeFormatter } from "../../../../utils/utils"
import { useUserPrefs } from "../../../UserPreferences/ProvideUserPrefs";

export default function UserList () {

  const { t } = useTranslation()
  const { prefsData } = useUserPrefs()

  const filter = [
    {
      endpoint: backend_url.model_userlist,
      queryKeyToTyping: "USER_ID",
      title: t("UserList.USER_ID"),
      inputText: t("UserList.USER_ID"),
      firstKeyOption: "USER_ID",
      secondKeyOption: "",
      valueFilter: null,
      changingArray: [],
      formatField: "select",
      noMultiple: true,
      noObject: false,
      fetchResult: true,
    }
  ]

  const title = t("Title.ManageUsers")
  const url = backend_url.model_userlist
  const editKey = "USER_ID"

  const columns = [
    { 
      field: "EMAIL", 
      headerName: t("UserList.USER_ID"), 
      flex: 1,
      editable: true,
      // renderCell: (params) => {
      //   return (
      //     <Link 
      //       style={{ color: '#f69e80' }} 
      //       to={`/authorization/manage-user/${params.row.USER_ID}`}
      //       className="dark-mode-link-color"
      //     >
      //       {params.value}
      //     </Link>
      //   )
      // }

    },
    { 
      field: "NAME", 
      headerName: t("UserList.NAME"), 
      flex: 1, 
      editable: true 
    },
    { 
      field: "SURNAME", 
      headerName: t("UserList.SURNAME"), 
      flex: 1, 
      editable: true 
    },
    { 
      field: "PHONE_NUM", 
      headerName: t("UserList.PHONE_NUM"), 
      flex: 1, 
      editable: true 
    },
    { 
      field: "DATE_VALID_FROM", 
      headerName: t("UserList.DATE_VALID_FROM"), 
      flex: 1, 
      editable: true,
      type: 'date',
      renderCell: ({ value }) => dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone),
      renderEditCell: (params) => <DatePicker {...params} />,
    },
    { 
      field: "DATE_VALID_TO", 
      headerName: t("UserList.DATE_VALID_TO"), 
      flex: 1, 
      editable: true, 
      type: 'date',
      renderCell: ({ value }) => dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone),
      renderEditCell: (params) => <DatePicker {...params} />,
    },
    { 
      field: "isActive",
      headerName: t("UserList.isActive"),
      flex: 1, 
      editable: true 
    },
  ]


  const objBreadcrumb = [
    { label: t("Breadcrumb.home"), link: "/" },
    { label: t("Breadcrumb.authorization"), link: "" },
    { label: t("Breadcrumb.manage_user"), link: "/authorization/manage-user" },
  ]

  
  return (
    <HookCRUDTableUserList 
      initialColumn={columns}
      initialFilter={filter}
      initialBreadcrumb={objBreadcrumb}
      url={url}
      title={title}
      action={false}
      editKey={editKey}
    />
  )
  

}
