import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Button } from '@mui/material'
import { useFetch } from '../../../../hooks'
import { useTranslation } from 'react-i18next'
import { backend_url } from '../../../../settings'
import { toBodyRequest } from '../../../../utils/utils'
import { validatePassword } from '../../../../utils/validation';
import { LoadingWithText } from '../../../../components'
import SnackbarUtils from '../../../../utils/SnackbarUtils'
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CloseIcon from '@mui/icons-material/Close'


export default function ModalResetPassword({ open, handleClose , idUser}) {
  
  const { t } = useTranslation(['translation'])
  
  const tokenBpxRequest = localStorage.getItem('token_bpx')

  const request = useFetch()
  
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState({'password': false, 'repeat_password': false});
  const [state, setState] = useState({'password': '', 'repeat_password': ''})
  const [errors, setErrors] = useState({'password': '', 'repeat_password': ''})

  const form = [
    {
      id: "1",
      label: t("UserList.NEW_PASSWORD"),
      name: "password",
      type: "password",
    },
    {
      id: "2",
      label: t("UserList.REPEAT_NEW_PASSWORD"),
      name: "repeat_password",
      type: "password",
    }
  ]

  const handleClickShowPassword = (name) => setShowPassword((prev) => ({...prev, [name]: !prev[name]}));
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true)
   
    request.fetchData(
      backend_url.password_change +'/'+idUser,
      "PUT",
      JSON.stringify({password: state.password}),
      false,
      tokenBpxRequest,
      true,
      false,
      false,
    )
    .then(() => {
      handleClose()
      setLoading(false)
      setState({password:'', repeat_password: ''})
    })
    .catch((er) => {
      setLoading(false)
      SnackbarUtils.error(er.toString())
    })
  
    
  }

  const validatePass = (e) => {
    const {name, value} = e.target;
    if(name==='repeat_password'&&value!==state.password){
      return setErrors(prev=>({...prev, [name]: "Passwords do not match"}));
    }
    if(name==='password'&&state.repeat_password.length){
      value!==state.repeat_password?
        setErrors(prev=>({...prev, 'repeat_password': "Passwords do not match"})):
        setErrors(prev=>({...prev, 'repeat_password': ""}));
    }
    setErrors(prev=>({...prev, [name]: validatePassword(value)}))
  }


  const onChangeHandler = (e) => {
    const { name, value } = e.target

    validatePass(e);
    setState(prev=>({...prev, [name]: value}))
    
  }

  const displayForm = (fields) => {
    const mapping = fields.map((item, ind) => {
      const { id, label, name } = item
      return ( 
        <div className='bpx-userid-section' key={id}>
          <div className='bpx-userid-field'>
            <FormControl variant="outlined" size="small" >
              <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
              <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword[name] ? 'text' : 'password'}
                  name={name}
                  error={errors[name] ? true : false}
                  value={state[name]}
                  fullWidth
                  autoComplete={'new-password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>handleClickShowPassword(name)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword[name] ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={onChangeHandler}
                  onBlur={validatePass}
                  label={label}
              />
              {!!errors[name].length && (
                <FormHelperText error id="accountId-error">
                {errors[name]}
                </FormHelperText>
            )}
            </FormControl>
            </div>
          </div>
      )  
    })
    return mapping
  }


  const displayBlockForm = () => {

    return (
      <div className='bpx-block-dialog-wrap'>
        <DialogTitle  id="alert-dialog-title" sx={{textAlign: 'center', paddingBottom: 0}}>
          {t("MODAL.RESET_PASSWORD_TITLE")}
          <div className="close-for-modal-window">
            <IconButton onClick={handleClose} className="wrap-cancel-modal">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
          <DialogContent> 
          <div  style={{borderTop: '2px solid black'}}>
            <p style={{textAlign: 'center'}}>
                  {t("MODAL.RESET_PASSWORD")}
            </p>
          
             { displayForm(form) }
          </div>
           
          </DialogContent>
          <DialogActions  sx={{paddingTop: '0 !important'}}>
            <Button onClick={handleClose}  variant="outlined" >
            {t("MODAL.BUTTONS.CANCEL")}
            </Button>
            <Button onClick={handleSubmit} autoFocus variant="contained" color="primary" disabled={(!Object.values(state).every(it=>it.length))||Object.values(errors).some(it=>it.length)}>
              {t("MODAL.BUTTONS.SAVE")}
            </Button>
          </DialogActions>
      </div>
    )
  }


  const displayLoading = (
    loading 
    ? <LoadingWithText text="Your request is being processed, please wait." size={50} /> 
    : displayBlockForm()
  )
//sx={{ '& .MuiDialog-paper': { minWidth: '858px' } }}
  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "300px", 
          },
        },
      }}
    >
      <div className='bpx-userid-modal'>
        <form style={{ height: "100%" }} onSubmit={handleSubmit} >
          { displayLoading }
        </form>
      </div>
    </Dialog>
  )


}
