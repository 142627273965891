import React, { useEffect, useState, useCallback, useRef } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useFetch } from '../../../hooks'
import { exportBlob, getParam } from "../../../utils/utils"
import CircularProgress from '@mui/material/CircularProgress'
import SnackbarUtils from '../../../utils/SnackbarUtils'
import { backend_url } from '../../../settings'
import { useTranslation } from 'react-i18next'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Checkbox from '@mui/material/Checkbox';
import { flexbox, grid } from '@mui/system'


export function CircularProgressWithLabel(props) {

  const regex = /Generated (\d+) lines/
  const desiredText = props.value.find(item => regex.test(item))
  let numberOfLines
  if (desiredText) {
    const match = regex.exec(desiredText)
    numberOfLines = match[0]
  } else {
    numberOfLines = "Starting..."
  }

  const displayMessage = (
    <div className="dialog-loading-text">
      <div>
        We are preparing your report. <br />
        Please wait, this may take a while.
      </div>
      <div>
        Status: {props.value.join() === "Export complete" ? "Completed" : numberOfLines}
      </div>
    </div>
  )

  return (
    <div>
      {/* <Box className='modal-circle-loading'>
        <CircularProgress size={60}/>
      </Box> */}
      <div className='modal-circle-text'>
        {displayMessage}
      </div>
    </div>
  )
}


export default function ModalWindow({ open, setOpen, setting, hideMenu }) {

  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const request = useFetch()
  const { t } = useTranslation('translation')

  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState([])
  const [count, setCount] = useState("")
  const [temParameters, setTemParameters] = useState({ IS_SUMMARY: false })
  const errorAttemptRef = useRef(0);

  const [languageOption, setLanguageOption] = useState("EN")
  const countries = [
    { code: 'EN', label: 'User setup (standard is English EN)' },
    { code: 'PL', label: 'Polish PL' },
  ]

  const value_bu = localStorage.getItem('bu_bpx')

  let intervalCount

  const displayMessages = {
    getFileName: () => SnackbarUtils.success(JSON.stringify('The file was successfully added to the download')),
    getFileNameError: () => SnackbarUtils.error(JSON.stringify('No such file exists')),

    fileStatus: () => SnackbarUtils.success(JSON.stringify('Export complete')),
    fileStatusError: () => SnackbarUtils.error(JSON.stringify('Error - generation of file failed')),

    getFile: () => SnackbarUtils.success(JSON.stringify('Your file has been successfully downloaded')),
    getFileError: () => SnackbarUtils.error(JSON.stringify('The file was not downloaded')),
  }


  useEffect(() => {
    if (!setting.endpointCount) {
      if (setting.isSummary) {
        setTemParameters(prev => ({ ...prev, ...setting.summaryFilter }))
      }
      return
    }

    request.fetchData(
      setting.endpointCount,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    )
      .then(status => {
        setCount(status.rows_in_excel)
      })

  }, [open])


  const getFile = (filename) => {
    return request.fetchData(
      `${backend_url.get_excel_file}?filename=${filename}&business_unit=${value_bu}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false,
      true // Blob 
    )
      .then(data => {
        clearInterval(intervalCount)
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        })
        exportBlob(blob, `${setting.fileName}.xlsx`)
        displayMessages.getFile()
     
        hideMenu?.()
        setOpen(false)
        setLoading(false)

      })
      .catch(e => {
        displayMessages.getFileError()
      })

  }

  function downloadFile(filename) {
    intervalCount = setInterval(() => getFile(filename), 5000);
  }

  const getStatus = (fileName) => {
    
    request.fetchData(
      `${backend_url.get_excel_file_status}?filename=${fileName}&business_unit=${value_bu}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    )
      .then(fileStatus => {

        setProgress([fileStatus.export_status])

        //console.log(fileStatus)
        if (fileStatus.export_status&&fileStatus.export_status[0].includes("Export complete")) {
          clearInterval(intervalCount);
          downloadFile(fileName);
        }
        if (fileStatus.export_status&&fileStatus.export_status[0].includes("Error")) {
          clearInterval(intervalCount);    
          setOpen(false)
          setLoading(false)
          displayMessages.fileStatusError()
        }
      })
    .catch(() => {
      if(errorAttemptRef.current>4){
        clearInterval(intervalCount);    
        setOpen(false)
        setLoading(false)
        displayMessages.fileStatusError()
      }else{
        errorAttemptRef.current = errorAttemptRef.current + 1
      }
    })
  }

  function getFileStatus(fileName) {
    intervalCount = setInterval(() => getStatus(fileName), 3000);
  }
  //IS_SUMMARY

  const requestExcel = useCallback(() => {
    setLoading(true)
    let temQuery = ''
    if (setting.isSummary) {
      temQuery = '&' + getParam(temParameters).toString()
    }
    request.fetchData(
      setting.endpoint + '&LANG=' + languageOption + temQuery,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    )
      .then(({ filename }) => {

        if (filename) {
          getFileStatus(filename)
        }

      })
      .catch(e => {
        displayMessages.getFileNameError()
      })

  }, [displayMessages, request])


  const onCloseHandle = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const displayLoading = (
    <CircularProgressWithLabel value={progress} />
  )

  const displayText = (
    <div style={{ textAlign: "center" }}>
      <p style={{ fontWeight: "bold" }}>Are you sure you want to proceed with the download?</p>
      <p style={{ fontStyle: "italic", fontSize: "14px" }}>
        Note: Large number of records (e.g. more than 1000) can take a long time to <br />
        extract. Consider using extra filters before proceeding with download.
      </p>
    </div>
  )

  const onCheckBoxSummary = (e) => {
    console.log(e)
    setTemParameters(prev => ({ ...prev, IS_SUMMARY: e.target.checked }))
  }

  const onFilterSummary = (e) => {
    setTemParameters(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }


  const filtersSummary = [
    {
      field: 'EXP_SUBTOTALS_BY',
      headerName: t("TradeExpeditiureMonitor.SUMMARY.EXPENDITURE_SUBTOTALS_BY"),
      valueOptions: [
        {
          value: 'ExternalCategory',
          name: t("TradeExpeditiureMonitor.SUMMARY.ExternalCategory"),
        },
        {
          value: 'ExternalClass',
          name: t("TradeExpeditiureMonitor.SUMMARY.ExternalClass"),
        },
        {
          value: 'ExternalSubclass',
          name: t("TradeExpeditiureMonitor.SUMMARY.ExternalSubclass"),
        },
        {
          value: 'ExternalSpendType',
          name: t("TradeExpeditiureMonitor.SUMMARY.ExternalSpendType"),
        }
      ]
    },
    {
      field: 'EXP_SUMMARY_FOR',
      headerName: t("TradeExpeditiureMonitor.SUMMARY.EXPENDITURE_SUMMARY_FOR"),
      valueOptions: [
        {
          value: 'Inv. Nature and Mechanic',
          name: t("TradeExpeditiureMonitor.SUMMARY.InvNatureAndMechanic"),
        }
      ]
    },
  ]


  const displayTextForTrade = () => {

    return (
      <>
        {t("TradeExpeditiureMonitor.Export_modal1")}<b>{t("TradeExpeditiureMonitor.Export_modal2")}</b>{t("TradeExpeditiureMonitor.Export_modal3")}

        <span style={{ display: 'block', fontSize: '0.875rem' }}><i>{t("TradeExpeditiureMonitor.Export_modal4")}</i></span>
        {
          setting.isSummary ?
            <>
              <Box sx={{ display: 'grid', gridTemplateColumns: '45% 55%', gridColumnGap: '10px', marginTop: '1rem' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>{t("TradeExpeditiureMonitor.Export_modal5")}</Box>
                <Box>
                  <Checkbox
                    checked={temParameters.IS_SUMMARY}
                    onChange={onCheckBoxSummary}
                  />
                </Box>

              </Box>
              <hr style={{ marginTop: 0 }}></hr>
              <Box>

                {temParameters.IS_SUMMARY && filtersSummary.map((it, ind) => {

                  const { headerName, field, valueOptions } = it;
                  return (
                    <div className='bpx-modal-add-data' key={ind} style={{ display: 'grid', gridTemplateColumns: '45% 55%', gridColumnGap: '10px' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>{headerName}</Box>
                      <FormControl sx={{ m: 1 }} size="small">
                        <Select
                          labelId="demo-select-small"
                          name={field}
                          value={temParameters[field] || ''}
                          onChange={onFilterSummary}
                          className='bpx-modal-add-data-field'
                        >
                          {
                            valueOptions.map((item, i) => {

                              return <MenuItem key={i} value={item.value} sx={{ fontSize: '12px' }} >{item.name}</MenuItem>
                            })
                          }
                        </Select>
                      </FormControl>
                    </div>
                  )
                })
                }
              </Box>
            </> :
            <Box sx={{ fontSize: '0.875rem', marginTop: '2rem' }}>
              <i>{t("TradeExpeditiureMonitor.Export_modal6")}</i>
              <hr style={{ marginTop: 0 }}></hr>
            </Box>
        }


      </>
    )
  }

  const displayScroller = (
    <div style={{ marginTop: "2rem", width: "100%" }}>
      <Autocomplete
        id="country-select-demo"
        options={countries}
        autoHighlight
        size='small'
        onChange={(e, v) => {
          setLanguageOption(v.code)
        }}
        defaultValue={countries[0]}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Choose a language"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
          />
        )}
      />
    </div>
  )

  const displayButtons = (
    <>
      <Button variant="outlined" onClick={onCloseHandle}>Cancel</Button>
      <Button variant="contained" color="primary" onClick={requestExcel} disabled={loading}>Download</Button>
    </>
  )

  return (
    <Dialog
      open={open}
      onClose={onCloseHandle}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className='dialog-fonts'>
        <DialogTitle id="alert-dialog-title">
          <div className="dialog-fonts-title">{"Export data"}</div>
          <div className="close-for-modal-window">
            <IconButton
              onClick={onCloseHandle}
              className="wrap-cancel-modal"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent sx={{ minHeight: "140px", minWidth: "600px" }}>
          {loading ? displayLoading : setting.isTrade ? displayTextForTrade() : displayText}
          {loading ? "" : displayScroller}
        </DialogContent>
        <DialogActions>
          {loading ? "" : displayButtons}
        </DialogActions>
      </div>
    </Dialog>
  )
}
