import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import { GridOverlay } from '@mui/x-data-grid-pro'
import { LinearProgress } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'
import Checkbox from '@mui/material/Checkbox'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from 'react-i18next'
import FormControl from '@mui/material/FormControl';
import moment from 'moment'
import { useUserPrefs } from "../../pages/UserPreferences/ProvideUserPrefs";
import { Grid, FormHelperText, Typography } from "@mui/material";
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { useFetch } from "../../hooks"
import { backend_url } from "../../settings"
import { getParam, numberFormatter, dateTimeFormatter, checkObjectKey, applyFilters, checkSize } from "../../utils/utils"
import {LoadingWithText} from '../index'

function DialogReprocess({ open, onCloseHandle, handleSubmit, data }) {

  const {dialogData, type} = data

  const { t } = useTranslation('translation')
  const { prefsData } = useUserPrefs()
  const request = useFetch()
  const value_bu = localStorage.getItem("bu_bpx")
  const tokenBpxRequest = localStorage.getItem("token_bpx")
  const [loading, setLoading] = useState(true)
  const [aoData, setAoData] = useState([])
  const templateDate = {
    reprocess: {
      headerName: t("Payment.DIALOG.REPROCESS.headerName"),
      buttonCancel: t("MODAL.BUTTONS.CANCEL"),
      buttonOk: t("MODAL.BUTTONS.REPROCESS"),
      errorClosed1: t("Payment.DIALOG.REPROCESS.errorClosed1"),
      errorClosed2: t("Payment.DIALOG.REPROCESS.errorClosed2"),
      warningMultipleNotClosed: t("Payment.DIALOG.REPROCESS.warningMultipleNotClosed1") + dialogData.PAYMENT_ID + t("Payment.DIALOG.REPROCESS.warningMultipleNotClosed2"),
    },
    cancel: {
      headerName: t("Payment.DIALOG.CANCEL.headerName"),
      buttonCancel: t("MODAL.BUTTONS.NO"),
      buttonOk: t("MODAL.BUTTONS.YES"),
      errorClosed1: t("Payment.DIALOG.CANCEL.errorClosed1"),
      errorClosed2: t("Payment.DIALOG.CANCEL.errorClosed2"),
      warningMultipleNotClosed: t("Payment.DIALOG.CANCEL.warningMultipleNotClosed1") + dialogData.PAYMENT_ID + t("Payment.DIALOG.CANCEL.warningMultipleNotClosed2"),
    }
  }

  useEffect(()=>{
    if(open){
      setLoading(true)
      const queryParams = {
        BUSINESS_UNITS: value_bu,
        PAYMENT_ID: dialogData.PAYMENT_ID
      }
      const queryString = getParam(queryParams).toString()
      request.fetchData(
        `${backend_url.payments_list}?${queryString}`,
        "GET",
        null,
        false,
        tokenBpxRequest,
        false
      )
      .then(data => {
        setLoading(false)
        setAoData(data)
      })
      .catch(()=>{
        setLoading(false)
      })
    }
  }, [open])


  const onHandleSubmit = (e)=>{
    e.preventDefault();
    setLoading(true)
    handleSubmit(dialogData.PAYMENT_ID, type);
   }
 

  
  const displayBlockForm = () => {

    const closeAo = aoData.filter(it=>it.STATUS === 'CLOSED')
    const notCloseAo = aoData.filter(it=>it.STATUS!== 'CLOSED')
    return (
      <div className='bpx-block-dialog-wrap bpx-block-recalculate'>
        <DialogTitle className='bpx-header-regenerate' sx={{ paddingBottom: 0 }}>
          <div className='bpx-title-regenerate'>
            {templateDate[type].headerName}
          </div>
          <div className="close-for-modal-window">
            <IconButton onClick={onCloseHandle} className="wrap-cancel-modal">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <form onSubmit={onHandleSubmit}>
          <DialogContent className='bpx-block-dialog-content'>

            <div className='bpx-block-dialog-top'>
              <div style={{fontSize: '1.125rem', fontWeight: 'bold', padding: '10px 0'}}>{t("Payment.DIALOG.GENERAL_INFO")}:</div>
              <Grid container spacing={1}>
               <Grid item xs={6}>
                {t("Payment.DIALOG.Payment_Id")}: {dialogData.PAYMENT_ID}</Grid>   
                <Grid item xs={6}>
                {t("Payment.DIALOG.Doc_date")}: {dialogData.DOCUMENT_DATE}</Grid>  
                <Grid item xs={6}>
                {t("Payment.DIALOG.ExternalRef")}: {dialogData.CUSTOMER_REF_DOC}</Grid>   
                <Grid item xs={6}>
                {t("Payment.DIALOG.Post_date")}: {dialogData.POSTING_DATE}</Grid>   
                </Grid> 
                {aoData.length? <>
                  <div style={{fontSize: '1.125rem', fontWeight: 'bold', padding: '20px 0 10px'}}>{t("Payment.DIALOG.Related_ao")}:</div>
              <Grid container spacing={1}>
                {aoData.map((it, ind)=>(
                  <Grid item xs={6} key={ind}>
                     <div>{t("Payment.DIALOG.AO_Id")}: {it.AO_ID}</div>
                      <div  style={{paddingTop: '8px'}}>{t("Payment.DIALOG.Activity_Id")}: {it.ACTIVITY_ID}</div>
                      <div  style={{paddingTop: '8px'}}>{t("Payment.DIALOG.Amount")}: {it.CONFIRMED_AMOUNT} {it.CONFIRMED_CURRENCY}</div>
                  </Grid>
                ))}
                
                </Grid>
                </> : null}
                {closeAo.length>0||notCloseAo.length>1?
                <>
                <br/>
                <hr/>
                {closeAo.length>0&& <FormHelperText sx={{color: "red"}}>{templateDate[type].errorClosed1 + closeAo.map(it=>it.AO_ID).join('; ') + templateDate[type].errorClosed2}</FormHelperText>}
                  {notCloseAo.length>1&&<FormHelperText sx={{color: "#ed7d31"}}>{templateDate[type].warningMultipleNotClosed}</FormHelperText>}
                
                </>: null
                  
                }
                
            </div>
          </DialogContent>

          <DialogActions>
          <Button
              variant="outlined"
              // color="primary"
              onClick={onCloseHandle}
            >
              {templateDate[type].buttonCancel}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              {templateDate[type].buttonOk}
            </Button>
          </DialogActions>
        </form>
      </div>
    )

  }
  const displayLoading = (
    loading
      ? <LoadingWithText text="Your request is being processed, please wait." size={50} />
      : displayBlockForm()
  )

  return (
    <Dialog
      open={open}
      onClose={onCloseHandle}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ '& .MuiDialog-paper': { minWidth: '800px' } }}
    >
      {displayLoading}
    </Dialog>
  )

}

export default DialogReprocess;