import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Button, TextField } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useTranslation } from 'react-i18next'
import { LoadingWithText } from '../../../../components'
import { validatePassword } from '../../../../utils/validation';
import SnackbarUtils from '../../../../utils/SnackbarUtils'
import moment from 'moment'
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CloseIcon from '@mui/icons-material/Close'


export default function ModalAddNewUser({ open, handleClose, handleSubmit, prevState=null, loadingFetch }) {
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [errorText, setErrorText] = useState({'PASSWORD_HASH': '', 'REPEAT_PASSWORD': ''})
  const [state, setState]= useState({});
  const [showPassword, setShowPassword] = useState({'PASSWORD_HASH': false, 'REPEAT_PASSWORD': false});

  const { t } = useTranslation(['translation'])

  useEffect(()=>{
    setLoading(loadingFetch)
  }, [loadingFetch])

  const initialFormAddUser = [
    {
      id: "1",
      label: t("UserList.EMAIL"),
      name: "EMAIL",
      type: "email",
      required: true,
      value: "",
      isDisabled: true
    },
    {
      id: "2",
      label: t("UserList.PASSWORD"),
      name: "PASSWORD_HASH",
      type: "password",
      hiddenEdit: true,
      subType: 'password',
      required: true,
      value: ""
    },
    {
      id: "10",
      label: t("UserList.REPEAT_PASSWORD"),
      name: "REPEAT_PASSWORD",
      type: "password",
      hiddenEdit: true,
      subType: 'password',
      required: true,
      value: ""
    },
    {
      id: "3",
      label: t("UserList.NAME"),
      name: "NAME",
      type: "text",
      required: true,
      value: ""
    },
    {
      id: "4",
      label: t("UserList.SURNAME"),
      name: "SURNAME",
      type: "text",
      required: true,
      value: ""
    },
    {
      id: "5",
      label: t("UserList.PHONE_NUM"),
      name: "PHONE_NUM",
      type: "number",
      required: true,
      value: ""
    },
    {
      id: "6",
      label: t("UserList.DATE_VALID_FROM"),
      name: "DATE_VALID_FROM",
      type: "date",
      required: true,
      value: ""
    },
    {
      id: "7",
      label: t("UserList.DATE_VALID_TO"),
      name: "DATE_VALID_TO",
      type: "date",
      required: true,
      value: ""
    },
    {
      id: "8",
      label: t("UserList.isActive"),
      name: "isActive",
      type: "hidden",
      required: false,
      value: "Active"
    },
    {
      id: "9",
      label: t("UserList.isActive"),
      name: "USER_ID",
      type: "hidden",
      required: false,
      value: ""
    }
  ]

  const [form, setForm] = useState(initialFormAddUser);

  useEffect(()=>{
    setError({})
    if(prevState){
      setState(prevState)
      const transform = initialFormAddUser.filter(it=> !it.hiddenEdit).map(it=>it.isDisabled?({...it, disabled: true}):it)
      setForm(transform);
    } else {
      const obj = {};
  
      setForm(initialFormAddUser.map(it=>{        
        obj[it.name]=it.value
        it.disabled = false;
        return it;
      }));
      setState(obj);
    }
  }, [open])


  const handleClickShowPassword = (name) => setShowPassword((prev) => ({...prev, [name]: !prev[name]}));
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formatFunc = (value) => {
    return moment(new Date(value)).format("YYYY-MM-DD")
  }

  const isValidateEmail = (value)=>{
    if(!value){
      return false
    }
    let currentEmails = value
      .split(',')
      .filter((e) => e && e.trim());
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    for (let i = 0; i < currentEmails.length; i++) {
      if (!regex.test(currentEmails[i].replace(/\s/g, ''))) {
        return false;
      }
    }
    return true
  }

  const isValidateDate = (from, to)=>{
    return from 
    && to 
    && new Date(from) <= new Date(to) 
    && from!=='9999-12-31' 
    && to!=='9999-12-31';
  }

  const validatePass = (e) => {
    const {name, value} = e.target;
    if(name==='REPEAT_PASSWORD'&&value!==state.PASSWORD_HASH){
      setErrorText(prev=>({...prev, [name]: "Passwords do not match"}));
      setError(prev=>({...prev,[name]: true }))
      return
    }
    if(name==='PASSWORD_HASH'&&state.REPEAT_PASSWORD.length){
      if(value!==state.REPEAT_PASSWORD){
        setErrorText(prev=>({...prev, 'REPEAT_PASSWORD': "Passwords do not match"}));
        setError(prev=>({...prev,'REPEAT_PASSWORD': true }))
      }else{
        setErrorText(prev=>({...prev, 'REPEAT_PASSWORD': ""}));
        setError(prev=>({...prev, 'REPEAT_PASSWORD': false}))
      }
    }
    setErrorText(prev=>({...prev, [name]: validatePassword(value)}))
    setError(prev=>({...prev, [name]: !!validatePassword(value).length}))
  }

  const isValidatePassword = (password, repeat_password)=>{
    if(prevState){
      return true;
    }
    return password.length && password===repeat_password && Object.values(errorText).every(it=>!it.length);
  }

  const isValidateData = ()=>{
    const errors = {}
    form
      .filter(it=>it.required)
      .map(item => {
        if (!state[item.name]){
          errors[item.name] = true;
        }else {
          errors[item.name] = false;
        }
        return item;
      });


    const validateDate = !isValidateDate(state.DATE_VALID_FROM, state.DATE_VALID_TO);
    errors.DATE_VALID_FROM = validateDate;
    errors.DATE_VALID_TO = validateDate;
    
    errors.EMAIL = !isValidateEmail(state.EMAIL);

    const validatePassword = !isValidatePassword(state.PASSWORD_HASH, state.REPEAT_PASSWORD);
    errors.PASSWORD_HASH = validatePassword;
    errors.REPEAT_PASSWORD = validatePassword

    setError(errors);

    return Object.values(errors).includes(true);
    
  }

  const onHandleSubmit = (event) => {
    event.preventDefault();
    const isNew = !prevState;
    
    setLoading(true)

    const check = isValidateData();

    if (check) {
      SnackbarUtils.error("Please add correct data.")
      setLoading(false);
      return 
    } else {
      delete state.user_in_validity_period
      if(isNew){
        handleSubmit({...state, USER_ID: state.EMAIL}, isNew);
      }else {
        handleSubmit(state, isNew);
        
      }
    }
    
  }


  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setState(prev=>({...prev, [name]: value}))
    if(name==='PASSWORD_HASH'||name==='REPEAT_PASSWORD'){
      validatePass(event)
    }else{
      setError(prev=>({...prev, [name]: !value.length}))
    }
   
  }

  const onChangeDate = (typing, name) => {
  
    if (typing === formatFunc(typing)) {
      setState(prev=>({...prev, [name]: typing}));
      setError(prev=>({...prev, [name]: !typing.length}))
    }
  
  }

  const displayForm = (fields) => {

    const mapping = fields.map(item => {
      const { id, label, name, type, required, value, disabled } = item
      
      switch(type) {
        case "password":
          return (
          <div className='bpx-userid-section' key={id}>
            <div className='bpx-userid-field'>
              <FormControl variant="outlined" size="small" >
              <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
              <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword[name] ? 'text' : 'password'}
                  name={name}
                  size="small"
                  error={error[name]}
                  autoComplete={'new-password'}
                  endAdornment={
                    <InputAdornment position="end" >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>handleClickShowPassword(name)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword[name] ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={onChangeHandler}
                  label={label}
                />
                {error[name] && (
                <FormHelperText error id="accountId-error">
                {errorText[name]}
                </FormHelperText>
            )}
                </FormControl>
            </div>
          </div>)
        case "text": case "number":  case "email":
          return (
            <div className='bpx-userid-section' key={id}>
              <div className='bpx-userid-field'>
                <TextField 
                  disabled={disabled}
                  error={error[name]}
                  type={type} 
                  variant="outlined"
                  autoComplete={'off'}
                  name={name} 
                  size="small"
                  placeholder={label}
                  required={required} 
                  value={state[name]||''}
                  onChange={onChangeHandler}
                  label={label}
                />
              </div>
            </div>
          )  
        case "date":
          return (
            <div className='bpx-userid-section' key={id}>
              <div className='bpx-userid-field'>
                <LocalizationProvider dateAdapter={AdapterDateFns} >
                  <DesktopDatePicker
                    inputFormat="yyyy-MM-dd"
                    mask="____-__-__"
                    maxDate='9999-12-30'
                    onChange={(format, typing) => onChangeDate(typing, name)}
                    value={state[name]||null}
                    onAccept={(value) => {
                      const convert = formatFunc(value)
                      onChangeDate(convert, name)
                    }}
                    renderInput={(params) => 
                      <TextField
                        {...params}
                        error={error[name]}
                        name={name}
                        size="small"
                        required={required}  
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "YYYY-MM-DD",
                        }}
                      /> 
                    }
                    label={label}
                  />
                </LocalizationProvider>
              </div>
            </div>
          )  
        case "hidden": 
          return (
            <input 
              key={id}
              name={name}
              type="hidden"
              value={value}
            /> 
          )
      }
    })

    return mapping
  }


  const displayBlockForm = () => {

    return (
      <div className='bpx-block-dialog-wrap'>
        <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center', paddingBottom: 0}}>
            {prevState? t("UserList.CHANGE_DATA") : t("UserList.ADD_DATA")}
            <div className="close-for-modal-window">
              <IconButton onClick={handleClose} className="wrap-cancel-modal">
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent> 
            <div  style={{borderTop: '2px solid black', paddingTop: '10px'}}>
              { displayForm(form) }
            </div>
            
          </DialogContent>
          <DialogActions sx={{paddingTop: '0 !important'}} >
            <Button onClick={handleClose} variant="outlined" >
              {t("MODAL.BUTTONS.CANCEL")}
            </Button>
            <Button onClick={onHandleSubmit} autoFocus variant="contained" color="primary" >
              {prevState ? t("MODAL.BUTTONS.SAVE") : t("MODAL.BUTTONS.CREATE")}
            </Button>
          </DialogActions>
      </div>
    )
  }


  const displayLoading = (
    loading 
    ? <LoadingWithText text="Your request is being processed, please wait." size={50} /> 
    : displayBlockForm()
  )

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className='bpx-userid-modal'>
        <form style={{ height: "100%" }} onSubmit={onHandleSubmit}>
          { displayLoading }
        </form>
      </div>
    </Dialog>
  )


}
