import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'
function LoadingWithText (props) {

  const { text, size } = props

  return (
    <div className="bpx-wrap-loading">
      <div>
      <React.Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="rgb(10, 100, 220)" />
            <stop offset="100%" stopColor="rgb(120, 175, 240)" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
    </React.Fragment>
        <p>{ text }</p>
      </div>
    </div>
  )
}

export default LoadingWithText
